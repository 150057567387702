class SignalingServer {
  constructor(broadcastUrl, currentUserId, csrfToken, localVideoEl, studyRoomId) {
    this.broadcastUrl = broadcastUrl;
    this.currentUserId = currentUserId;
    this.ice = {
      iceServers: [
        { urls: ["stun:stun.example.com", "stun:stun-1.example.com"] }
      ]
    }
    this.peerConnection = null;
    this.csrfToken = csrfToken;
    this.localVideoEl = localVideoEl;
    this.studyRoomId = studyRoomId;
    this.senders = [];
    this.eventCandidates = [];
    this.stream = null;
    this.audioEnabled = true;
    this.videoShared = false;
  }

  initOffer() {
    this.peerConnection = new RTCPeerConnection(this.ice);

    this.peerConnection.onnegotiationneeded = this.handleOnNegotiationNeeded.bind(this);
    this.peerConnection.onicecandidate = this.handleIceCandidate.bind(this);
    this.peerConnection.ontrack = this.handleOnTrack.bind(this);
    this.peerConnection.onremovetrack = this.handleRemoveTrackEvent.bind(this);

    navigator.mediaDevices.getUserMedia({audio: true, video: true})
      .then(localStream => {
        this.localVideoEl.srcObject = localStream;
        this.localVideoEl.muted = false;
        this.stream = localStream;

        localStream.getTracks().forEach(track => {
          if (track.kind === "video") { track.enabled = this.videoShared }
          if (track.kind === "audio") { track.enabled = this.audioEnabled }

          this.senders.push(this.peerConnection.addTrack(track, localStream));
        });
      })
      .catch(this.handleGetUserMediaError.bind(this))
  }

  handleOnNegotiationNeeded(event) {
    this.createOffer();
  }

  // called on onnegotiationneeded
  createOffer(event) {
    if (this.peerConnection.signalingState != "stable") return;

    this.peerConnection.createOffer()
      .then(offer => {
        return this.peerConnection.setLocalDescription(offer);
      })
      .then(() => {
        this.broadcast({
          type: "VIDEO_OFFER",
          from: this.currentUserId,
          sdp: JSON.stringify(this.peerConnection.localDescription)
        })
      })
  }

  initAnswer(data) {
    this.peerConnection = new RTCPeerConnection(this.ice);
    this.peerConnection.onicecandidate = this.handleIceCandidate.bind(this);
    this.peerConnection.ontrack = this.handleOnTrack.bind(this);

    let sdp = JSON.parse(data.sdp);

    this.peerConnection.setRemoteDescription(new RTCSessionDescription(sdp))
      .then(() => {
        return navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
        })
      })
      .then(localStream => {
        this.localVideoEl.muted = true;
        this.stream = localStream;

        localStream.getTracks().forEach(track => {
          if (track.kind === "video") { track.enabled = this.videoShared }
          if (track.kind === "audio") { track.enabled = this.audioEnabled }

          this.senders.push(this.peerConnection.addTrack(track, localStream));
        });
      })
      .then(() => {
        return this.peerConnection.createAnswer();
      })
      .then( answer => {
        return this.peerConnection.setLocalDescription(answer);
      })
      .then(() => {
        return this.broadcast({
          type: "VIDEO_ANSWER",
          from: this.currentUserId,
          sdp: JSON.stringify(this.peerConnection.localDescription)
        })
      })
      .then(() => {
        this.eventCandidates.forEach(candidate => {
          this.broadcast({
            type: "NEW_ICE_CANDIDATE",
            from: this.currentUserId,
            candidate: JSON.stringify(candidate)
          });
        })
      })
      .catch(this.handleGetUserMediaError.bind(this))
  }

  handleVideoAnswer(data) {
    let sdp = JSON.parse(data.sdp);

    this.peerConnection.setRemoteDescription(new RTCSessionDescription(sdp));
    this.eventCandidates.forEach(candidate => {
      this.broadcast({
        type: "NEW_ICE_CANDIDATE",
        from: this.currentUserId,
        candidate: JSON.stringify(candidate)
      });
    })
  }

  handleIceCandidate(event) {
    if (event.candidate) {
      this.eventCandidates.push(event.candidate)
    }
  }

  handleNewIceCandidate(data) {
    if (data.candidate && this.peerConnection && this.peerConnection.currentRemoteDescription) {
      let candidate = new RTCIceCandidate(JSON.parse(data.candidate))

      this.peerConnection.addIceCandidate(candidate);
    }
  }

  shareVideo() {
    this.videoShared = true

    if (this.peerConnection) {
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true
      })
      .then(localStream => {
        this.localVideoEl.srcObject = localStream;

        this.senders
          .find(sender => sender.track.kind === "video")
          .replaceTrack(localStream.getTracks()[0]);
      });
    }
  }

  stopShareVideo() {
    this.videoShared = false;

    if (this.peerConnection) {
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true
      })
      .then(localStream => {
        let videoTrack = localStream.getTracks()[0]
        videoTrack.enabled = this.videoShared

        this.senders
          .find(sender => sender.track.kind === "video")
          .replaceTrack(videoTrack);
      });
    }
  }

  shareScreen() {
    navigator.mediaDevices.getDisplayMedia()
      .then(displayMediaStream => {
        this.senders
          .find(sender => sender.track.kind === "video")
          .replaceTrack(displayMediaStream.getTracks()[0]);
      });
  }

  stopSharingScreen() {
    this.shareVideo();
  }

  muteAudio() {
    this.audioEnabled = false;

    if (this.peerConnection) {
      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      })
      .then(localStream => {
        let audioTrack = localStream.getTracks()[0]
        audioTrack.enabled = this.audioEnabled;

        this.senders
          .find(sender => sender.track.kind === "audio")
          .replaceTrack(audioTrack);
      });
    }
  }

  unMuteAudio() {
    this.audioEnabled = true;

    if (this.peerConnection) {
      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      })
      .then(localStream => {
        let audioTrack = localStream.getTracks()[0]
        audioTrack.enabled = this.audioEnabled;

        this.senders
          .find(sender => sender.track.kind === "audio")
          .replaceTrack(audioTrack);
      });
    }
  }

  handleGetUserMediaError(e) {
    switch(e.name) {
      case "NotFoundError":
      alert("Unable to open your call because no camera and/or microphone" +
            "were found.");
      this.hangUpCall()
      break;
      case "SecurityError":
      this.hangUpCall()
      case "PermissionDeniedError":
        // Do nothing; this is the same as the user canceling the call.
        break;
      default:
        alert("Error opening your camera and/or microphone: " + e.message);
        this.hangUpCall()
        break;
    }
  }

  hangUpCall() {
    this.closeVideoCall();
    this.broadcast({
      type: "HANG_UP"
    });
  }

  handleOnTrack(event) {
    let remoteVideo = document.getElementById("remote-video");
    let establishCallSection = document.querySelector("#establish-call-section");
    let remoteVideoContainer = document.getElementById("remote-video-container");

    establishCallSection.classList.add("hidden")
    remoteVideoContainer.classList.remove("hidden")

    remoteVideo.srcObject = event.streams[0];
    remoteVideo.autoplay = "autoplay";
  }

  handleRemoveTrackEvent(event) {
    let stream = document.getElementById("remote-video").srcObject;
    let trackList = stream.getTracks();

    if (trackList.length == 0) {
      this.closeVideoCall();
    }
  }

  closeVideoCall() {
    let remoteVideo = document.getElementById("remote-video");
    let localVideo = document.getElementById("local-video");

    if (this.peerConnection) {
      this.peerConnection.ontrack = null;
      this.peerConnection.onremovetrack = null;
      this.peerConnection.onremovestream = null;
      this.peerConnection.onicecandidate = null;

      if (remoteVideo.srcObject) {
        remoteVideo.srcObject.getTracks().forEach(track => track.stop());
      }

      if (localVideo.srcObject) {
        localVideo.srcObject.getTracks().forEach(track => track.stop());
      }

      this.peerConnection.close();
      this.peerConnection = null;

      remoteVideo.removeAttribute("src");
      remoteVideo.removeAttribute("srcObject");
      localVideo.removeAttribute("src");
      localVideo.removeAttribute("src");
    }
  }

  broadcast(data) {
    const headers = new Headers({
      "content-type": "application/json",
      "X-CSRF-TOKEN": this.csrfToken,
    });

    fetch(this.broadcastUrl, {
      method: "POST",
      body: JSON.stringify(Object.assign({ room: this.studyRoomId }, data)),
      headers,
    });
  }
}

export default SignalingServer;
