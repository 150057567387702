var jstz = require('jstimezonedetect');

document.addEventListener('turbo:load', function() {
  // used for registration form
  let userTimeZone = document.querySelector('#user_time_zone')

  if (userTimeZone) {
    userTimeZone.value = jstz.determine().name();
  }

  // used for google auth
  let googleButton = document.querySelector('#google-button')

  if (googleButton) {
    let expires = new Date();
    expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000));

    document.cookie = `timezone = ${jstz.determine().name()};expires= + ${expires.toUTCString()}`
  }
});
