import React, { useState, forwardRef } from "react"
import { subMonths, addMonths } from "date-fns"
import DatePicker from "react-datepicker"

export default ({ name, id, outerHandler }) => {
  const [startDate, setStartDate] = useState(new Date());
  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <input
      name={name}
      id={id}
      className="shadow text-sm appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      onClick={onClick} ref={ref}
      onChange={() => {}}
      value={value}
    />
  ));

  const handleSetDate = date => {
    outerHandler(date)
    setStartDate(date)
  }

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => handleSetDate(date)}
      customInput={<DateInput />}
      showYearDropdown
      yearDropdownItemNumber={99}
      scrollableYearDropdown
    />
  );
}
