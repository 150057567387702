import consumer from "./consumer"

consumer.subscriptions.create("UnreadMessagesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    let messagesCounterHolder = document.querySelector("#messages-info")
    let messagesCounter = messagesCounterHolder.querySelector("#messages-counter")

    if (data.read_at) {
      if(messagesCounter) {
        let messageCount = parseInt(messagesCounter.innerHTML)

        if(messageCount == 1) {
          messagesCounter.remove()
        } else {
          messagesCounter.innerHTML = messageCount - 1
        }
      }
    } else {
      if(messagesCounter) {
        messagesCounter.innerHTML = parseInt(messagesCounter.innerHTML) + 1
      } else {
        messagesCounterHolder.insertAdjacentHTML("beforeend",
          `<span id="messages-counter" class="block absolute bottom-0 right-0 flex justify-center items-center shadow text-xss rounded-full w-3 h-3 text-white bg-syzygy-yellow">
            1
          </span>`
        )
      }
    }
  }
});
