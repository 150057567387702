require("mobius1-selectr/dist/selectr.min")

// can we only use this without require, looks like not
import Selectr from "mobius1-selectr/dist/selectr.min"

document.addEventListener("turbo:load", function() {
  let searchableUserCountry = document.querySelector("#search-user-country");
  let searchUserSkills = document.querySelector("#search-user-skills");
  let searchableUserLang = document.querySelector("#search-user-lang");
  // let selectableCountry = document.querySelector("#select-country");

  if (searchUserSkills) {
    let userSkillSelector = new Selectr(searchUserSkills, {
      defaultSelected: false,
      multiple: true,
      placeholder: "Search skills by name",
      closeOnScroll: true,
      messages: {
        noResults: "Sorry, we still don't have users with that skill"
      }
    });

    if(searchUserSkills.dataset.skillNames) {
      userSkillSelector.setValue(searchUserSkills.dataset.skillNames.split(","))
    }
    userSkillSelector.on("selectr.select", function(option) {
      this.close();
    })
  }

  if (searchableUserCountry) {
    let userCountrySelector = new Selectr(searchableUserCountry, {
      multiple: true
    })
    if(searchableUserCountry.dataset.countryNames) {
      userCountrySelector.setValue(searchableUserCountry.dataset.countryNames.split(","))
    }
  }

  if (searchableUserLang) {
    let userLangSelector = new Selectr(searchableUserLang, {
      multiple: true
    })
    console.log(searchableUserLang.dataset);
    if(searchableUserLang.dataset.userLanguages) {
      console.log(searchableUserLang.dataset.userLanguages.split(","));
      userLangSelector.setValue(searchableUserLang.dataset.userLanguages.split(","))
    }
  }
});
