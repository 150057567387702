import consumer from "./consumer"
import SignalingServer from "../src/signaling_server.js"

const START_CALL = "START_CALL";
const JOIN_ROOM = "JOIN_ROOM";
const REMOVE_USER = "REMOVE_USER";
const EXCHANGE = "EXCHANGE";

let currentUser;
let sigServer;

document.addEventListener("turbo:load", function() {
  document.querySelectorAll('[data-channel-subscribe="video"]').forEach(element => {
    const videoEl = document.querySelector('[data-room-id]');

    const videoId = videoEl.dataset.roomId;
    const studyRoomId = videoEl.dataset.studyRoomId;
    currentUser = parseInt(videoEl.dataset.userId);
    const csrfToken = document.querySelector("[name=csrf-token]").content;
    let localVideo = document.getElementById("local-video");

    consumer.subscriptions.create(
      {
        channel: "VideoCallChannel",
        room: studyRoomId
      },
      {
        connected: () => {
          sigServer = new SignalingServer(
            "/videocall",
            currentUser,
            csrfToken,
            localVideo,
            studyRoomId);
        },

        received: data => {
          switch (data.type) {
            case "VIDEO_OFFER":
              if (data.from == currentUser) return;
              return offerCall(data);
            case "VIDEO_ANSWER":
              if (data.from == currentUser) return;
              sigServer.handleVideoAnswer(data);
              showCallOptions();
            case "NEW_ICE_CANDIDATE":
              if (data.from == currentUser) return;
              return sigServer.handleNewIceCandidate(data);
            case "HANG_UP":
              if (data.from == currentUser) return;
              closeLocalCall();
              return sigServer.closeVideoCall();
            default:
              return;
          }
        }
      }
    );
  })
});

document.addEventListener("turbo:load", () => {
  const startCallBtn = document.getElementById("start-call-button");
  const rejectCallBtn = document.getElementById("dismiss-call");
  const endCallBtn = document.getElementById("end-call");
  const shareVideoBtn = document.getElementById("share-video");
  const stopShareVideoBtn = document.getElementById("stop-share-video");
  const screenShareBtn = document.getElementById("share-screen");
  const stopScreenShareBtn = document.getElementById("stop-share-screen");
  const openChatBtn = document.getElementById("open-chat");
  const hideChatBtn = document.getElementById("hide-chat");
  const muteAudioBtn = document.getElementById("mute-audio");
  const unMuteAudioBtn = document.getElementById("unmute-audio");

  if(startCallBtn) {
    startCallBtn.addEventListener("click", startCall);
  }
  if(rejectCallBtn) {
    rejectCallBtn.addEventListener("click", endCall);
  }
  if(endCallBtn) {
    endCallBtn.addEventListener("click", endCall);
  }
  if(shareVideoBtn) {
    shareVideoBtn.addEventListener("click", shareVideo);
  }
  if(stopShareVideoBtn) {
    stopShareVideoBtn.addEventListener("click", stopShareVideo);
  }
  if(screenShareBtn) {
    screenShareBtn.addEventListener("click", shareScreen);
  }
  if(stopScreenShareBtn) {
    stopScreenShareBtn.addEventListener("click", stopSharingScreen);
  }
  if(openChatBtn) {
    openChatBtn.addEventListener("click", openChat);
  }
  if(hideChatBtn) {
    hideChatBtn.addEventListener("click", closeChat);
  }
  if(muteAudioBtn) {
    muteAudioBtn.addEventListener("click", muteAudio);
  }
  if(unMuteAudioBtn) {
    unMuteAudioBtn.addEventListener("click", unMuteAudio);
  }
});

const offerCallAcception = (data) => {
  loadLocalVideo();
  const acceptCallBtn = document.getElementById("accept-call");

  acceptCallBtn.addEventListener("click", function() {
    sigServer.initAnswer(data);
    showCallOptions();
  });
}

const startCall = async () => {
  await sigServer.initOffer();
  loadLocalVideo();
};

const endCall = async () => {
  await sigServer.hangUpCall();
  closeLocalCall();
}

const shareVideo = () => {
  sigServer.shareVideo();
  let shareVideoBtn = document.getElementById("share-video");
  let stopShareVideoBtn = document.getElementById("stop-share-video");

  shareVideoBtn.classList.add("hidden")
  stopShareVideoBtn.classList.remove("hidden")
}

const stopShareVideo = () => {
  sigServer.stopShareVideo();
  let shareVideoBtn = document.getElementById("share-video");
  let stopShareVideoBtn = document.getElementById("stop-share-video");

  shareVideoBtn.classList.remove("hidden")
  stopShareVideoBtn.classList.add("hidden")
}

const shareScreen = () => {
  sigServer.shareScreen();
  let screenShareBtn = document.getElementById("share-screen");
  let stopScreenShareBtn = document.getElementById("stop-share-screen");

  screenShareBtn.classList.add("hidden");
  stopScreenShareBtn.classList.remove("hidden");
}

const stopSharingScreen = () => {
  sigServer.stopSharingScreen();
  let screenShareBtn = document.getElementById("share-screen");
  let stopScreenShareBtn = document.getElementById("stop-share-screen");

  stopScreenShareBtn.classList.add("hidden");
  screenShareBtn.classList.remove("hidden");
}

const muteAudio = () => {
  sigServer.muteAudio();
  let muteAudioBtn = document.getElementById("mute-audio");
  let unMuteAudioBtn = document.getElementById("unmute-audio");

  muteAudioBtn.classList.add("hidden");
  unMuteAudioBtn.classList.remove("hidden");
};

const unMuteAudio = () => {
  sigServer.unMuteAudio();
  let muteAudioBtn = document.getElementById("mute-audio");
  let unMuteAudioBtn = document.getElementById("unmute-audio");

  muteAudioBtn.classList.remove("hidden");
  unMuteAudioBtn.classList.add("hidden");
};


const openChat = () => {
  let chat = document.getElementById("video-chat");
  let mainVideoSection = document.getElementById("main-video-section");
  let chatMessages = document.querySelector(".video-chat-messages");
  let openChatBtn = document.getElementById("open-chat");
  let hideChatBtn = document.getElementById("hide-chat");

  openChatBtn.classList.add("hidden");
  hideChatBtn.classList.remove("hidden");
  chat.classList.remove("hidden");
  chatMessages.scrollTop = chatMessages.scrollHeight;
  mainVideoSection.classList.add("w-9/12");
  mainVideoSection.classList.remove("w-full");
}

const closeChat = () => {
  let chat = document.getElementById("video-chat");
  let mainVideoSection = document.getElementById("main-video-section");
  let openChatBtn = document.getElementById("open-chat");
  let hideChatBtn = document.getElementById("hide-chat");

  openChatBtn.classList.remove("hidden");
  hideChatBtn.classList.add("hidden");
  chat.classList.add("hidden");
  mainVideoSection.classList.remove("w-9/12");
  mainVideoSection.classList.add("w-full");
}

const showCallOptions = () => {
  let shareVideoBtn = document.getElementById("share-video");
  let screenShareBtn = document.getElementById("share-screen");
  let openChatBtn = document.getElementById("open-chat");
  let endCallBtn = document.getElementById("end-call");

  screenShareBtn.classList.remove("hidden");
  openChatBtn.classList.remove("hidden");
  endCallBtn.classList.remove("hidden");
}

const loadLocalVideo = () => {
  MicroModal.show("call-modal");
  let modal = document.getElementById("call-modal");

  modal.addEventListener("mouseenter", () => {
    let callOptions = modal.querySelector("#call-block");

    callOptions.classList.remove("bottom-100");
    callOptions.classList.add("bottom-0");
  })

  modal.addEventListener("mouseleave", () => {
    let callOptions = modal.querySelector("#call-block");

    callOptions.classList.remove("bottom-0");
    callOptions.classList.add("bottom-100");
  })
}

const closeLocalCall = () => {
  MicroModal.close("call-modal");
}

const offerCall = (data) => {
  offerCallAcception(data);
}
