import $ from 'jquery'
import Rails from '@rails/ujs'

global.$ = jQuery;

document.addEventListener("turbo:load", function() {
  let toggleSearchFiltersBtn = document.querySelector("#toggle-search-filters-btn")

  if (toggleSearchFiltersBtn) {
    toggleSearchFiltersBtn.addEventListener("click", function() {
      let searchFilters = document.querySelector("#search-filters");

      searchFilters.classList.toggle("hidden");
    })
  }

  document.querySelectorAll(".search-radio-btn").forEach(el => {
    el.addEventListener('click', function() {
      let radioBtnText = el.querySelector(".radio-btn-text")

      radioBtnText.classList.remove("bg-syzygy-white")
      radioBtnText.classList.add("bg-syzygy-blue")
      radioBtnText.classList.remove("text-syzygy-blue")
      radioBtnText.classList.add("text-syzygy-white")

      el.parentElement.querySelectorAll(".search-radio-btn").forEach(childEl => {
        if (childEl != el) {
          let radioBtnTextOfOther = childEl.querySelector(".radio-btn-text")
          radioBtnTextOfOther.classList.remove("bg-syzygy-blue")
          radioBtnTextOfOther.classList.add("bg-syzygy-white")
          radioBtnTextOfOther.classList.add("text-syzygy-blue")
          radioBtnTextOfOther.classList.remove("text-syzygy-white")
        }
      })
    })
  })

  document.querySelectorAll(".search-checkbox-label").forEach(el => {
    el.addEventListener("click", function(event) {
      event.stopPropagation()
    })
  })

  document.querySelectorAll(".search-check-box").forEach(el => {
    el.addEventListener('click', function() {
      if (el.classList.contains("bg-syzygy-white")) {
        el.classList.remove("bg-syzygy-white")
        el.classList.add("bg-syzygy-blue")
      } else {
        el.classList.remove("bg-syzygy-blue")
        el.classList.add("bg-syzygy-white")
      }
      if (el.classList.contains("text-syzygy-blue")) {
        el.classList.remove("text-syzygy-blue")
        el.classList.add("text-syzygy-white")
      } else {
        el.classList.add("text-syzygy-blue")
        el.classList.remove("text-syzygy-white")
      }
    })
  })

  let loadMoreBtn = document.querySelector("#load-users-btn");

  if (loadMoreBtn) {
    loadMoreBtn.addEventListener("click", function() {
      let usersPaginationEl = document.querySelector("#users-pagination");
      let nextPage = usersPaginationEl.querySelector(".page.next").firstChild.getAttribute("href");

      Rails.ajax({
        url: nextPage,
        type: 'get'
      });
    })
  }
});
