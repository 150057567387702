document.addEventListener("turbo:load", function() {
  const menuToggle = document.getElementById("menu");

  if(menuToggle) {
    menuToggle.addEventListener("click", event => {
      let menuLinks = document.getElementById("menu-links");
      menuLinks.classList.toggle("hidden");
    })
  }

  // close navigation if opened and clicked outside
  window.onclick = function(e) {
    if (!e.target.matches('.dropbtn')) {
      let dropdowns = document.getElementsByClassName("dropdown-content");
      let i;

      for (i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (!openDropdown.classList.contains('hidden')) {
          openDropdown.classList.add('hidden');
        }
      }
    }
  }
});
