document.addEventListener('turbo:load', function() {
  const alertBlock = document.querySelector(".alert-holder");
  const noticeBlock = document.querySelector(".notice-holder");

  if (alertBlock) {
    window.setTimeout(function() {
      alertBlock.classList.add("hidden");
    }, 7000)

    let closeFlashBtn = alertBlock.querySelector(".close-flash-btn")
    closeFlashBtn.addEventListener("click", function() {
      alertBlock.remove()
    });
  }

  if (noticeBlock) {
    window.setTimeout(function() {
      noticeBlock.classList.add("hidden");
    }, 7000)

    let closeFlashBtn = noticeBlock.querySelector(".close-flash-btn")
    closeFlashBtn.addEventListener("click", function() {
      noticeBlock.remove()
    });
  }
});
