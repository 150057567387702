document.addEventListener("turbo:load", function() {
  let chatEmoticonTrigger = document.getElementById("chat-emoticon-trigger");

  if(chatEmoticonTrigger) {
    let chatHolder = chatEmoticonTrigger.closest(".chat-holder");
    let chat = chatHolder.querySelector("#chat");
    let chatMessages = chat.querySelector(".chat-messages");
    let chatMessagesAndEmoticonsHolder = chat.querySelector(".chat-messages-and-emoticons-holder");
    let chatId = chat.dataset.roomId;
    let chatForm = document.querySelector(`#room-form-${chatId}`);
    let chatTextField = document.querySelector(`#chat-${chatId}-message`);

    function setIcon(ev) {
      let hexValue = ev.target.dataset.emojiHex;
      let hexValueAsArrayOfInts = hexValue.split("-").map(hex => parseInt(hex, 16));
      chatTextField.value = `${chatTextField.value} ${String.fromCodePoint(...hexValueAsArrayOfInts)}`
    }

    chatEmoticonTrigger.addEventListener("click", function() {
      let chatEmoticons = document.getElementById("chat-emoticons");

      chatEmoticons.classList.toggle("hidden");
      chatMessages.scrollTop = chatMessages.scrollHeight;
      chatMessagesAndEmoticonsHolder.scrollTop = chatMessagesAndEmoticonsHolder.scrollHeight;

      document.querySelectorAll(".emoji-icon")
        .forEach( emoji => emoji.addEventListener("click", setIcon));

      chatTextField.addEventListener("focus", function() {
        if(!chatEmoticons.classList.contains("hidden")) {
          chatEmoticons.classList.add("hidden");

          document.querySelectorAll(".emoji-icon")
            .forEach( emoji => emoji.removeEventListener("click", setIcon))
        }
      });

      chatForm.addEventListener("submit", function() {
        if(!chatEmoticons.classList.contains("hidden")) {
          chatEmoticons.classList.add("hidden");

          document.querySelectorAll(".emoji-icon")
            .forEach( emoji => emoji.removeEventListener("click", setIcon))
        }
      });
    });
  }

  let videoChatEmoticonTrigger = document.getElementById("video-chat-emoticon-trigger");

  if(videoChatEmoticonTrigger) {
    let videoChatHolder = videoChatEmoticonTrigger.closest(".chat-holder");
    let videoChat = videoChatHolder.querySelector("#v-chat");
    let videoChatMessages = videoChatHolder.querySelector(".video-chat-messages");
    let videoChatMessagesAndEmoticonsHolder = videoChatHolder.querySelector(".video-chat-messages-and-emoticons-holder");
    let videoChatId = videoChat.dataset.roomId;
    let videoChatForm = document.querySelector(`#video-room-form-${videoChatId}`);
    let videoChatTextField = document.querySelector(`#video-chat-${videoChatId}-message`);

    function setIcon(ev) {
      let hexValue = ev.target.dataset.emojiHex;
      let hexValueAsArrayOfInts = hexValue.split("-").map(hex => parseInt(hex, 16));
      videoChatTextField.value = `${videoChatTextField.value} ${String.fromCodePoint(...hexValueAsArrayOfInts)}`
    }

    videoChatEmoticonTrigger.addEventListener("click", function() {
      let videoChatEmoticons = document.getElementById("video-chat-emoticons");

      videoChatEmoticons.classList.toggle("hidden");
      videoChatMessages.scrollTop = videoChatMessages.scrollHeight;
      videoChatMessagesAndEmoticonsHolder.scrollTop = videoChatMessagesAndEmoticonsHolder.scrollHeight;

      document.querySelectorAll(".emoji-icon")
        .forEach( emoji => emoji.addEventListener("click", setIcon));

      videoChatTextField.addEventListener("focus", function() {
        if(!videoChatEmoticons.classList.contains("hidden")) {
          videoChatEmoticons.classList.add("hidden");

          document.querySelectorAll(".emoji-icon")
            .forEach( emoji => emoji.removeEventListener("click", setIcon))
        }
      });

      videoChatForm.addEventListener("submit", function() {
        if(!videoChatEmoticons.classList.contains("hidden")) {
          videoChatEmoticons.classList.add("hidden");

          document.querySelectorAll(".emoji-icon")
            .forEach( emoji => emoji.removeEventListener("click", setIcon))
        }
      });
    });
  }
});
