document.addEventListener("turbo:load", function() {
  let invitationMenuToggles = document.querySelectorAll(".invitation-menu");

  if(invitationMenuToggles) {
    invitationMenuToggles.forEach( invitationMenu => {
      invitationMenu.addEventListener("click", event => {
        let menuParentEl = invitationMenu.parentNode;
        let menuLinks = menuParentEl.querySelector(".study-invitation-links")

        menuLinks.classList.toggle("hidden");

        invitationMenuToggles.forEach( invMenu => {
          if (invMenu != invitationMenu) {
            let menuParentEl = invMenu.parentNode;
            let menuLinks = menuParentEl.querySelector(".study-invitation-links")

            if (!menuLinks.classList.contains("hidden")) { menuLinks.classList.add("hidden") }
          }
        });
      });
    });
  }
});
