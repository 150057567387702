require("autolink-js/autolink-min");
import consumer from "./consumer";

document.addEventListener("turbo:load", function() {
  document.querySelectorAll('[data-channel-subscribe="room"]').forEach(chat => {
    const chatId = chat.dataset.roomId;
    const userId = parseInt(chat.dataset.userId);
    const csrfToken = document.querySelector("[name=csrf-token]").content;
    let chatMessages = null;
    let chatMessagesAndEmoticonsHolder = null;
    let chatMessageInput = null;

    chatMessages = chat.querySelector(".chat-messages");
    chatMessageInput = document.querySelector(`#chat-${chatId}-message`);
    chatMessagesAndEmoticonsHolder = chat.querySelector(".chat-messages-and-emoticons-holder");

    if(chatMessages) { chatMessages.scrollTop = chatMessages.scrollHeight }
    if(chatMessagesAndEmoticonsHolder) { chatMessagesAndEmoticonsHolder.scrollTop = chatMessagesAndEmoticonsHolder.scrollHeight }

    consumer.subscriptions.create(
      {
        channel: "RoomChannel",
        room: chatId
      },
      {
        connected() {
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          this.appendLine(data);

          if(chatMessageInput) { chatMessageInput.value = "" }

          if(chatMessages) { chatMessages.scrollTop = chatMessages.scrollHeight }
          if(chatMessagesAndEmoticonsHolder) { chatMessagesAndEmoticonsHolder.scrollTop = chatMessagesAndEmoticonsHolder.scrollHeight }

          let chatIsOpened = document.querySelector(`#chat-${chatId}-message`);

          if(userId != parseInt(data.user_id) && chatIsOpened) {
            const headers = new Headers({
              "content-type": "application/json",
              "X-CSRF-TOKEN": csrfToken,
            });

            fetch(`/room_messages/${data.id}`, {
              method: "PUT",
              body: JSON.stringify({read_at: true}),
              headers: headers
            });
          }
        },

        appendLine(data) {
          const html = this.createLine(data);

          if(chatMessages) { chatMessages.insertAdjacentHTML("beforeend", html) }
        },

        withAutoLink(message) {
          return message.autoLink({ target: '_blank', rel: 'nofollow' });
        },

        createLine(data) {
          let currentUserMessageDiv = `
            <div class="mr-8 bg-syzygy-blue py-4 px-2 text-sm text-white rounded-t-3xl rounded-r-3xl">
              ${this.withAutoLink(data.message)}
            </div>
            <div class="mb-1 text-syzygy-blue">
              <span class="text-sm font-semibold">${data.user_firstname}</span>
              <span class="text-xs font-thin">just now</span>
            </div>
          `
          let inviteeUserMessageDiv = `
            <div class="flex justify-end ml-8 bg-syzygy-marine py-4 px-2 text-sm text-white rounded-t-3xl rounded-l-3xl">
              ${this.withAutoLink(data.message)}
            </div>
            <div class="flex justify-end items-end mb-1 text-syzygy-blue">
              <span class="text-xs font-thin mr-2">just now</span>
              <span class="text-sm font-semibold">${data.user_firstname}</span>
            </div>
          `

          if(parseInt(data.user_id) == userId) {
            return currentUserMessageDiv
          } else {
            return inviteeUserMessageDiv
          }
        }
      }
    );
  })
})
