require("autolink-js/autolink-min");
import consumer from "./consumer";

document.addEventListener("turbo:load", function() {
  document.querySelectorAll('[data-channel-subscribe="video-chat"]').forEach(chat => {
    const chatId = chat.dataset.roomId;
    const userId = parseInt(chat.dataset.userId);
    const csrfToken = document.querySelector("[name=csrf-token]").content;
    let videoChatMessagesAndEmoticonsHolder = null;
    let videoChatMessages = null;
    let videoChatMessageInput = null;

    videoChatMessages = chat.querySelector(".video-chat-messages");
    videoChatMessageInput = document.querySelector(`#video-chat-${chatId}-message`);
    videoChatMessagesAndEmoticonsHolder = chat.querySelector(".video-chat-messages-and-emoticons-holder");

    if(videoChatMessagesAndEmoticonsHolder) { videoChatMessagesAndEmoticonsHolder.scrollTop = videoChatMessagesAndEmoticonsHolder.scrollHeight }
    if(videoChatMessages) { videoChatMessages.scrollTop = videoChatMessages.scrollHeight }

    consumer.subscriptions.create(
      {
        channel: "VideoChatChannel",
        room: chatId
      },
      {
        connected() {
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          this.appendLine(data);

          if(videoChatMessageInput) { videoChatMessageInput.value = "" }

          if(videoChatMessagesAndEmoticonsHolder) { videoChatMessagesAndEmoticonsHolder.scrollTop = videoChatMessagesAndEmoticonsHolder.scrollHeight }
          if(videoChatMessages) { videoChatMessages.scrollTop = videoChatMessages.scrollHeight }

          let chatIsOpened = document.querySelector(`#video-chat-${chatId}-message`);

          if(userId != parseInt(data.user_id) && chatIsOpened) {
            const headers = new Headers({
              "content-type": "application/json",
              "X-CSRF-TOKEN": csrfToken,
            });

            fetch(`/room_messages/${data.id}`, {
              method: "PUT",
              body: JSON.stringify({read_at: true}),
              headers: headers
            });
          }
        },

        appendLine(data) {
          const videoHtml = this.createVideoChatLine(data);

          if(videoChatMessages) { videoChatMessages.insertAdjacentHTML("beforeend", videoHtml) }
        },

        withAutoLink(message) {
          return message.autoLink({ target: '_blank', rel: 'nofollow' });
        },

        createVideoChatLine(data) {
          let currentUserMessageDiv = `
            <div class="text-white">
              <span class="text-sm font-semibold">${data.user_firstname}</span>
              <span class="text-xs font-thin">${data.created_at}</span>
            </div>
            <div class="text-sm mr-8 bg-syzygy-blue rounded px-2 py-2">${this.withAutoLink(data.message)}</div>
          `
          let inviteeUserMessageDiv = `
            <div class="flex justify-end items-end text-white">
              <span class="text-xs font-thin mr-2">${data.created_at}</span>
              <span class="text-sm font-semibold">${data.user_firstname}</span>
            </div>
            <div class="text-sm ml-8 bg-syzygy-marine rounded px-2 py-2">${this.withAutoLink(data.message)}</div>
          `

          if(parseInt(data.user_id) == userId) {
            return currentUserMessageDiv
          } else {
            return inviteeUserMessageDiv
          }
        }
      }
    );
  })
})
