import React from "react"
import ReactDOM from "react-dom"
import { Controller } from "@hotwired/stimulus"
import CreatableSelect from "react-select/creatable"

// Connects to data-controller="react-select"
export default class extends Controller {
  initialize() {
    if(!this.data.get("initialized")) {
      this.initReactSelect()
    }
  }

  initReactSelect() {
    const select = this.element.querySelector("select");
    // we will store value here
    const hiddenInput = this.element.querySelector("input#skill_name")

    // hide rails select
    select.style.display = "none"

    const options = [...select.options]
    const defaultValue = select.selectedOptions[0].value

    const reactSelect = document.createElement("div")
    select.parentNode.insertBefore(reactSelect, select)

    const reactOptions = options.map(val => ({ value: val.innerHTML, label: val.innerHTML }))
    const reactDefaultValue = { label: defaultValue, value: defaultValue }

    const handleChange = selectedOption => {
      if (selectedOption.value) {
        hiddenInput.value = selectedOption.value
      }
    }

    ReactDOM.render(
      <CreatableSelect
        onChange={handleChange}
        defaultValue={reactDefaultValue}
        options={reactOptions}
      />,
      reactSelect
    )

    this.data.set("initialized", true);
  }
}
