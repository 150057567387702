import consumer from "./consumer"

consumer.subscriptions.create("WebNotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    let notificationCounterHolder = document.querySelector("#notification-info")
    let notificationCounter = notificationCounterHolder.querySelector("#notification-counter")

    if(notificationCounter) {
      notificationCounter.innerHTML = data.count
    } else {
      notificationCounterHolder.insertAdjacentHTML("beforeend",
        `<span class="block absolute bottom-0 right-0 flex justify-center items-center shadow text-xss rounded-full w-3 h-3 text-white bg-syzygy-yellow">
          ${data.count}
        </span>`
      )
    }
  }
});
