import React from "react"
import ReactDOM from "react-dom"
import { Controller } from "@hotwired/stimulus"
import ReactDatepicker from "../src/react/react_datepicker"

// Connects to data-controller="react-datepicker"
export default class extends Controller {
  initialize() {
    if(!this.data.get("initialized")) {
      this.initReactDatePicker()
    }
  }

  initReactDatePicker() {
    const originalInput = this.element.querySelector("input.hidden")
    // hide original input
    originalInput.style.display = "none"

    const reactWrapperDiv = document.createElement("div")
    originalInput.parentNode.insertBefore(reactWrapperDiv, originalInput)

    const handleDateChange = date => originalInput.value = date

    ReactDOM.render(
      <div className="w-full">
        <label
          className="block text-sm font-bold mb-2"
          htmlFor="user_date_of_birth"
        >
          Date of birth
        </label>
        <ReactDatepicker
          name="user[date_of_birth]"
          id="user_date_of_birth"
          outerHandler={handleDateChange}
        />
      </div>,
      reactWrapperDiv
    )

    this.data.set("initialized")
  }
}
